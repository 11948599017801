// ==================================================
//  スライダー（Slick）
// ==================================================
$(document).ready(function () {
  // スライダー①
  $(".message_image_slider-js").not(".slick-initialized").slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2700,
    speed: 1800,
    fade: true,
    // cssEase: "linear",
    dots: false,
    swipe: false,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
  });

  // スライダー②
  $(".photo_slider-js")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      autoplaySpeed: 0,
      speed: 10000,
      cssEase: "linear",
      slidesToShow: 2,
      centerMode: true,
      variableWidth: true,
      dots: false,
      swipe: false,
      arrows: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      rtl: true, // dir="rtl"
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    });
});

// ==================================================
//  パララックス（Simple Parallax）
// ==================================================
const parallaxImage = document.getElementsByClassName("parallax_item-js");
new simpleParallax(parallaxImage, {
  delay: 0.45,
  transition: "cubic-bezier(0, 0, 0, .1)",
});

// ==================================================
//  スクロールに合わせて追従ヘッダーのロゴ表示
// ==================================================
const mainvisual = "mainvisual";
const jsHeaderLogo = "header_logo-js";
const jsHeaderLogoActive = "header_logo-js-active";
const observerMainvisual = new IntersectionObserver((entries) => {
  for (const e of entries) {
    const jsHeaderLogoElement = document.querySelector("." + jsHeaderLogo);
    if (e.isIntersecting) {
      jsHeaderLogoElement.classList.remove(jsHeaderLogoActive);
    } else {
      jsHeaderLogoElement.classList.add(jsHeaderLogoActive);
    }
  }
});
observerMainvisual.observe(document.querySelector("." + mainvisual));
